function getOldInHouseHeader(data, showData) {
  showData.name = data.salesforce_property.name;
  showData.price = data.salesforce_property.price;
  showData.blockClassification = data.salesforce_property.block_classification;
  showData.layoutType = data.salesforce_property.layout_type_txt;
  showData.area = data.salesforce_property.block_area;
  showData.buildingNumber = data.salesforce_property.building_number;
}

function getOldInHouseImage(data, showData) {
  showData.appearanceImageLink = data.salesforce_property.appearance_image_link;
  showData.blockImageLink = data.salesforce_property.block_image_link;
}

function getOldInHouseIcon(data, showData) {
  const walkingToStation1 =
    data.salesforce_property.walking_to_nearest_station_1;
  const walkingToStation2 =
    data.salesforce_property.walking_to_nearest_station_2;
  const walkingToStation3 =
    data.salesforce_property.walking_to_nearest_station_3;
  const nearestStationRailway1 =
    data.salesforce_property.nearest_station_railway_1;
  const nearestStationRailway2 =
    data.salesforce_property.nearest_station_railway_2;
  const nearestStationRailway3 =
    data.salesforce_property.nearest_station_railway_3;
  if (
    (walkingToStation1 !== null &&
      walkingToStation1 > 0 &&
      walkingToStation1 <= 5) ||
    (walkingToStation2 !== null &&
      walkingToStation2 > 0 &&
      walkingToStation2 <= 5) ||
    (walkingToStation3 !== null &&
      walkingToStation3 > 0 &&
      walkingToStation3 <= 5)
  ) {
    showData.iconWalkingToNearestStation = true;
  }

  if (
    (nearestStationRailway1 !== null && nearestStationRailway2 !== null) ||
    (nearestStationRailway1 !== null && nearestStationRailway3 !== null) ||
    (nearestStationRailway2 !== null && nearestStationRailway3 !== null)
  ) {
    showData.iconNearestStationRailway = true;
  }
  const inputDate = new Date(
    data.salesforce_property.completion_year_and_month
  );
  const currentDate = new Date();
  const yearDifference = currentDate.getFullYear() - inputDate.getFullYear();
  const monthDifference =
    currentDate.getMonth() - inputDate.getMonth() + yearDifference * 12;
  if (monthDifference <= 120) {
    showData.iconYearAndMonth = true;
  }
  if (
    data.salesforce_property.floor_number ==
      data.salesforce_property.number_of_floors &&
    data.salesforce_property.floor_number != null &&
    data.salesforce_property.number_of_floors != null
  ) {
    showData.iconTopFloor = true;
  }
  if (
    (data.salesforce_property.area_of_roof_balcony != null &&
      data.salesforce_property.area_of_roof_balcony != 0) ||
    (data.salesforce_property.usage_fee_of_roof_balcony != null &&
      data.salesforce_property.usage_fee_of_roof_balcony != 0)
  ) {
    showData.iconRoofBalconyExistFlag = true;
  }
  if (
    (data.salesforce_property.area_of_private_garden != null &&
      data.salesforce_property.area_of_private_garden != 0) ||
    (data.salesforce_property.usage_fee_of_private_garden != null &&
      data.salesforce_property.usage_fee_of_private_garden != 0) ||
    (data.salesforce_property.area_of_private_terrace != null &&
      data.salesforce_property.area_of_private_terrace != 0) ||
    (data.salesforce_property.usage_fee_of_private_terrace != null &&
      data.salesforce_property.usage_fee_of_private_terrace != 0)
  ) {
    showData.iconPrivateExistFlag = true;
  }
}

function getOldInHouseTable(data, showData) {
  showData.name = data.salesforce_property.name;
  showData.locationPrefecture = data.salesforce_property.location_prefecture;
  showData.locationDistrict = data.salesforce_property.location_district;
  showData.locationDetail = data.salesforce_property.location_detail;

  showData.nearestStationRailway1 =
    data.salesforce_property.nearest_station_railway_1;
  showData.nearestStationRailway2 =
    data.salesforce_property.nearest_station_railway_2;
  showData.nearestStationRailway3 =
    data.salesforce_property.nearest_station_railway_3;
  showData.nearestStation1 = data.salesforce_property.nearest_station_1;
  showData.nearestStation2 = data.salesforce_property.nearest_station_2;
  showData.nearestStation3 = data.salesforce_property.nearest_station_3;
  showData.walkingTonNearestStation1 =
    data.salesforce_property.walking_to_nearest_station_1;
  showData.walkingTonNearestStation2 =
    data.salesforce_property.walking_to_nearest_station_2;
  showData.walkingTonNearestStation3 =
    data.salesforce_property.walking_to_nearest_station_3;
  showData.busToNearestStation1 =
    data.salesforce_property.bus_to_nearest_station_1;
  showData.busToNearestStation2 =
    data.salesforce_property.bus_to_nearest_station_2;
  showData.busToNearestStation3 =
    data.salesforce_property.bus_to_nearest_station_3;

  showData.price = data.salesforce_property.price;
  showData.layoutType = data.salesforce_property.layout_type_txt;
  showData.area = data.salesforce_property.block_area;
  showData.balconyArea = data.salesforce_property.balcony_area;
  showData.areaOfPrivateGarden =
    data.salesforce_property.area_of_private_garden;
  showData.usageFeeOfPrivateGarden =
    data.salesforce_property.usage_fee_of_private_garden;
  showData.areaOfPrivateTerrace =
    data.salesforce_property.area_of_private_terrace;
  showData.usageFeeOfPrivateTerrace =
    data.salesforce_property.usage_fee_of_private_terrace;
  showData.areaOfRoofBalcony = data.salesforce_property.area_of_roof_balcony;
  showData.usageFeeOfRoofBalcony =
    data.salesforce_property.usage_fee_of_roof_balcony;
  showData.completionDate = data.salesforce_property.completion_year_and_month;

  if (data.salesforce_property.delivery_available_date !== null) {
    showData.deliveryDate = data.salesforce_property.delivery_available_date;
  } else {
    showData.deliveryDate = data.salesforce_property.delivery_available_date;
  }
  showData.floorNumber = data.salesforce_property.floor_number;
  showData.numberOfFloors = data.salesforce_property.number_of_floors;
  showData.structureAndScale = data.salesforce_property.structure;
  showData.numberOfHouses = data.salesforce_property.number_of_houses;
  showData.rightType = data.salesforce_property.right_type;
  showData.saleCompany = data.salesforce_property.sale_company;
  showData.constructionCompany = data.salesforce_property.construction_company;
  showData.managementCompany = data.salesforce_property.management_company;
  showData.managementType = data.salesforce_property.management_type;
  showData.managerWorkingStyle = data.salesforce_property.manager_working_style;
  showData.managementFee = data.salesforce_property.management_fee;
  showData.repairReserveFee = data.salesforce_property.repair_reserve_fee;
  showData.parkingLotStatus = data.salesforce_property.parking_lot_status;
  showData.parkingLotNote = data.salesforce_property.parking_lot_note;
  showData.currentSituation = data.salesforce_property.current_situation;
  showData.tradeType = data.salesforce_property.trade_type;
  showData.propertyNote = data.salesforce_property.property_note;
  showData.updatedAt = data.salesforce_property.updated_at;
  showData.deliveryAvailableStatus =
    data.salesforce_property.delivery_available_status;
  showData.otherFee = data.salesforce_property.other_fee;
  showData.otherFeeNote = data.salesforce_property.other_fee_note;
  showData.salesPoint = data.salesforce_property.sales_point;
}
export {
  getOldInHouseHeader,
  getOldInHouseImage,
  getOldInHouseIcon,
  getOldInHouseTable,
};
