const showData = {
  name: null,
  locationPrefecture: null,
  locationDistrict: null,
  locationDetail: null,
  locationHouseNumber: null,
  nearestStationRailway1: null,
  nearestStationRailway2: null,
  nearestStationRailway3: null,
  nearestStation1: null,
  nearestStation2: null,
  nearestStation3: null,
  walkingTonNearestStation1: null,
  walkingTonNearestStation2: null,
  walkingTonNearestStation3: null,
  busToNearestStation1: null,
  busToNearestStation2: null,
  busToNearestStation3: null,
  price: null,
  layoutType: null,
  area: null,
  balconyArea: null,
  areaOfPrivateGarden: null,
  usageFeeOfPrivateGarden: null,
  areaOfPrivateTerrace: null,
  usageFeeOfPrivateTerrace: null,
  areaOfRoofBalcony: null,
  usageFeeOfRoofBalcony: null,
  structureAndScale: null,
  numberOfFloors: null,
  floorNumber: null,
  completionDate: null,
  deliveryDate: null,
  numberOfHouses: null,
  siteArea: null,
  useDistrict: null,
  buildingConfirmationNumber: null,
  rightType: null,
  sellerOwner1: null,
  sellerOwner2: null,
  sellerOwner3: null,
  sellerOwner4: null,
  sellerOwner5: null,
  constructionCompany: null,
  managerWorkingStyle: null,
  managementCompany: null,
  managementType: null,
  managementFee: null,
  repairReserveFee: null,
  deliveryDueDate: null,
  parkingLotCount: null,
  parkingLotNote: null,
  tradeType: null,
  propertyNote: null,
  updatedAt: null,
  blockClassification: null,
  appearanceImageLink: null,
  blockImageLink: null,
  iconWalkingToNearestStation: false,
  iconNearestStationRailway: false,
  iconConciergeService: false,
  iconAllDayMannedManagement: false,
  iconFurnished: false,
  iconTopFloor: false,
  iconPrivateExistFlag: false,
  iconRoofBalconyExistFlag: false,
  iconYearAndMonth: false,
  guideMapImageLink: null,
  saleCompany: null,
  parkingLotStatus: null,
  currentSituation: null,
  isShowButton: true,
  deliveryAvailableStatus: null,
  acquisitionDateOfBuildingConfirmation: null,
  blockNote: null,
  otherFee: null,
  otherFeeNote: null,
  salesPoint: null,
  buildingNumber: null,
};

export { showData };
